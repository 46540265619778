// Grid system
// .main {
//   @include make-col-ready();
//   @include media-breakpoint-up(sm) {
//     @include make-col($main-sm-columns);
//     .sidebar-primary & {
//       @include make-col($main-sm-columns - $sidebar-sm-columns);
//     }
//   }
// }
// .sidebar {
//   @include make-col-ready();
//   @include media-breakpoint-up(sm) {
//     @include make-col($sidebar-sm-columns);
//   }
// }

// To have a grid full width
// Just add the class no-gutters to row
// http://v4-alpha.getbootstrap.com/layout/grid/#no-gutters
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
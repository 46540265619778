html {
	font-size: 10px;
	line-height: 1.3em;
}

body {
	font-family: $font-pri;
	background: white;
	position: relative;
	color: $grey-dark;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrap {
	position: relative;

	// @include media-breakpoint-up(lg) {
	// 	padding-top: 90px;
	// }
}

.title {
	font-family: $font-pri;
	font-size: 3rem;
	line-height: 1.2em;
	font-weight: 500;
	//text-transform: uppercase;
	margin-bottom: 20px;

	@include media-breakpoint-down(lg) {
		font-size: 2.8rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 2.6rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 2.4rem;
	}

	@include media-breakpoint-down(xs) {
		font-size: 2.2rem;
	}
}

.subtitle {
	font-family: $font-pri;
	font-size: 3rem;
	line-height: 1.2em;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 30px;

	@include media-breakpoint-down(lg) {
		font-size: 2.8rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 2.6rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 2.4rem;
	}

	@include media-breakpoint-down(xs) {
		font-size: 2.2rem;
	}
}

.small-title {
	font-size: 1.8rem;
	color: $grey;
	font-weight: bold;

	@include media-breakpoint-down(md) {
		font-size: 1.7rem;
	}

	@include media-breakpoint-down(xs) {
		font-size: 1.6rem;
	}
}

a, p, li, td, th, .text, .text p {
	font-size: 1.8rem;
	color: $grey-dark;
	font-weight: 300;
	line-height: 1.7em;

	@include media-breakpoint-down(lg) {
		font-size: 1.7rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.6rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.5rem;
	}

	@include media-breakpoint-down(xs) {
		font-size: 1.4rem;
	}
}

ul > li {
	list-style: square;
}

ul > li > ul > li {
	list-style: circle;
}

nav {
	ul {
		margin: 0;
		padding: 0;
	}

	li {
		list-style: none;
	}
}

a {
	transition: all 0.2s ease-in-out;

	&:focus {
	  text-decoration: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 1px solid $color-pri;
  margin: 20px auto;
  padding: 0; 
}

iframe {
	border: 0;
}

.video-wrapper {
	// margin-top: 30px;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.table {
	thead {
		border-top: 0;
		border-bottom: 3px solid $grey-light;

		th {
			border-top: 0;
		}

		td, th {
			font-weight: bold;
			padding: 10px;
		}
	}

	tbody {
		& > tr {
			border-bottom: 1px solid $grey-light;

			& > th,
			& > td {
				border: 0;
				padding: 8px;
			}
		}
	}

	&.table-responsive {
		display: table;

		@include media-breakpoint-down(xs) {
			display: block;
		}
	}
}

.carousel-fade {
	.carousel-item {
		display: block;
		position: absolute;
		opacity: 0;
		transition: opacity .75s ease-in-out;

		&.active {
			opacity: 1;
		}
	}
}






@import url('https://fonts.googleapis.com/css?family=Raleway:400,400i,500, 500i,600, 600i, 700,700i');


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$grey-lighter: 					#efefef;
$grey-light: 						#d1d1d1;
$grey: 									#a6a497;
$grey-dark: 						#363636;

$orange: 								#f03c02;
$red:				 						#d4242b;
$green: 								#96c93d;
$gold: 									#d48100;

$color-pri:         		$grey-dark;
$color-sec:         		$gold;


// Font family
$font-pri:				'Raleway', sans-serif;
@mixin gradient($color1, $color2) {
	background-color: $color1;
	background: linear-gradient(to bottom,  $color1 0%,$color2 100%);
}

@mixin gradient-side($color1, $color2) {
	background-color: $color1;
	background: linear-gradient(to right,  $color1 0%,$color2 100%);
}

@mixin section-padding {
	padding: 60px 0;

	@include media-breakpoint-down(lg) {
		padding: 60px 0;
	}

	@include media-breakpoint-down(md) {
		padding: 40px 0;
	}

	@include media-breakpoint-down(sm) {
		padding: 30px 0;
	}

	@include media-breakpoint-down(xs) {
		padding: 30px 0;
	}
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}
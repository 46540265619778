.single-portfolio {

	.content-single {
		text-align: center;
		padding: 40px 0 0;
	}

	.title {
		font-family: $font-pri;
		font-weight: 400;
		text-transform: initial;
	}

	.text {
		margin-bottom: 30px;
	}

	.other-projects-wrapper {
		text-align: center;
		padding-bottom: 40px;
	}

	.other-projects {
		.grid-item {
			width: 100%;
			height: 200px;

			@include media-breakpoint-down(lg) {
				height: 170px;
			}

			@include media-breakpoint-down(md) {
				height: 170px;
			}

			@include media-breakpoint-down(sm) {
				height: 120px;
			}

			 @include media-breakpoint-down(xs) {
			 	height: 160px;
			 }
		}

		.portfolio-item {
			margin: 15px 0;
		}
	}
}
// .home {
// 	.wrap {
// 		@include media-breakpoint-up(lg) {
// 			padding-top: 75px;
// 		}
// 	}
// }

section.seo {
	background-color: $color-pri;
	text-align: center;
	padding: 5px;

	h1 {
		color: $grey;
		font-size: 1.3rem;
	}
}

.banner {
	width: 100%;
	background-color: $color-pri;
	position: relative;

	&:after {
		content: '\f107';
		font-family: 'FontAwesome';
		color: white;
		font-size: 5rem;
		line-height: 5rem;
		position: absolute;
		bottom: -30px;
		left: 50%;
		transform: translateX(-50%);
		background: $color-pri;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		padding: 5px 0;
		text-align: center;

		@include media-breakpoint-down(lg) {
			font-size: 4.5rem;
			line-height: 4.5rem;
			width: 55px;
			height: 55px;
		}

		@include media-breakpoint-down(md) {
			font-size: 4rem;
			line-height: 4rem;
			width: 50px;
			height: 50px;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.banner-content {
		position: absolute;
		bottom: -80px;
		left: 0;
		z-index: 2;

		@include media-breakpoint-down(lg) {
			bottom: -60px;
		}

		@include media-breakpoint-down(md) {
			bottom: -40px;
		}

		@include media-breakpoint-down(sm) {
			bottom: -30px;
		}

		@include media-breakpoint-down(xs) {
			bottom: -20px;
		}

		img {
			@include media-breakpoint-down(lg) {
				width: 400px;
			}

			@include media-breakpoint-down(md) {
				width: 350px;
			}

			@include media-breakpoint-down(sm) {
				width: 300px;
			}

			@include media-breakpoint-down(xs) {
				width: 150px;
			}
		}
	}

	.carousel-inner {
		height: 70vh;
		overflow: hidden;

		@include media-breakpoint-down(lg) {
			height: 600px;
		}

		@include media-breakpoint-down(md) {
			height: 500px;
		}

		@include media-breakpoint-down(sm) {
			height: 400px;
		}

		@include media-breakpoint-down(xs) {
			height: 250px;
		}
	}

	.carousel-item.active,
	.carousel-item-next,
	.carousel-item-prev {
		display: block;
	}

	.carousel-item {
		height: 100%;

		// &:before {
		//   content: '';
		//   position: absolute;
		//   top: 0; 
		//   left: 0;
		//   width: 100%; 
		//   height: 100%;  
		//   opacity: .6; 
		//   z-index: 1;
		//   background: $color-pri;
		// }

		img {
			width: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}

	.carousel-control-prev,
	.carousel-control-next {
	  top: 50%;
	  transform: translateY(-50%);
	  opacity: 1;
	  width: 5%;

	  @include media-breakpoint-down(lg) {
			width: 7%;
		}

		@include media-breakpoint-down(md) {
			width: 9%;
		}

		@include media-breakpoint-down(sm) {
			width: 12%;
		}

		@include media-breakpoint-down(xs) {
			width: 15%;
		}
	}

	.carousel-control-prev {
		left: 0;
	}

	.carousel-control-next {
		right: 0;
	}

	.carousel-control-prev-icon,
	.carousel-control-next-icon, {
		background-image: none;
	}

	.fa {
		color: white;
		font-size: 5rem;
		margin-top: -3px;

		&.fa-angle-left {
			margin-right: 4px;
		}

		&.fa-angle-right {
			margin-left: 4px;
		}
	}
}


section.about {
	padding: 80px 0;
	text-align: center;
	@include media-breakpoint-down(sm){
		padding: 50px 0 ;
	}
}


section.styles {
	@include section-padding;
	background: $color-pri;
	position: relative;

	@include media-breakpoint-up(lg) {
		padding-left: 5%;
		padding-right: 5%;
	}

	.container-fluid {
		@include media-breakpoint-down(md) {
			padding-left: 15px;
	  	padding-right: 15px;
		}
	}

	.content {
		text-align: center;
	}

	.title,
	.subtitle {
		font-family: $font-pri;
		font-weight: bold;
		text-transform: initial;
		color: white;
	}

	.subtitle {
		font-weight: 400;
		margin-top: 20px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
	}

	div[class^="col-"] {
		&:nth-child(3),
		&:nth-child(4), {
			@include media-breakpoint-between(sm,md) {
				.subtitle {
					margin-bottom: 0;
				}
			}
		}
	}

	.text {
		color: $grey;
		font-size: 1.4rem;
		margin: 0 10%;

		@include media-breakpoint-down(lg) {
			margin: 0;
		}
	}

	.kitchen-style {
		width: 100%;
		height: 500px;
		overflow: hidden;
		display: block;
		position: relative;

		@include media-breakpoint-down(lg) {
			height: 400px;
		}

		@include media-breakpoint-down(md) {
			height: 300px;
		}

		@include media-breakpoint-down(sm) {
			height: 250px;
		}

		@include media-breakpoint-down(xs) {
			height: 200px;
		}

		&:before {
		  content: '';
		  position: absolute;
		  top: 0; 
		  left: 0;
		  width: 100%; 
		  height: 100%;  
		  opacity: 0;
		  z-index: 1;
		  background: $color-sec;
		  transition: all 0.2s ease-in-out;
		}

		&:hover {
			&:before {
				opacity: .6;
			}
		}

		img {
			width: auto;
			height: 100%;
			max-width: initial;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);

			@include media-breakpoint-down(xs) {
				width: 100%;
				height: auto;
			}
		}
	}

	.number {
		position: absolute;
		bottom: 0;
		left: -10px;
		z-index: 2;
		font-family: $font-pri;
		font-size: 15rem;
		line-height: 0.6em;
		color: white;
		opacity: .6;

		@include media-breakpoint-down(lg) {
			font-size: 13rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 11rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 9rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 7rem;
		}
	}
}


.home {
	section.contact {
		background: rgba($grey, .9);

		&:before {
		  content: '';
		  position: absolute;
		  top: 0; 
		  left: 0;
		  width: 100%; 
		  height: 100%;  
		  z-index: -1;
		  background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			background-image: url('../images/Ledaan-decoration.jpg');
		}

		.title,
		.text {
			color: white;
		}

		.text {
			font-weight: bold;
		}
	}
}













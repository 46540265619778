.nav-pri {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    display: block !important;
  }

  @include media-breakpoint-down(sm) {
    display: none;
    border-top: 2px solid darken($color-pri, 5%);
    margin-top: 20px;
  }
}

.nav-sec {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: block !important;
  }

  @include media-breakpoint-down(lg) {
    right: 17px;
  }

  @include media-breakpoint-down(md) {
    right: 5px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &.open {
    position: absolute;
    top: 20px;
    z-index: 10;
    transform: translateY(0);

    @include media-breakpoint-down(xs) {
      top: 28px;
    }
  }
}

.nav-pri,
.nav-sec {
  li {
    list-style: none;
  }

  /* first level */
  .menu {
    font-size: 0;

    & > li {
      display: inline-block;
      margin: 0;

      @include media-breakpoint-down(sm) {
        width: 100%;
        // border-top: 2px solid darken($color-pri, 5%);
      }

      &.active {
        & > a {
          text-decoration: none;
          // font-weight: bold;
          color: $color-sec;

          &:hover {
            background: transparent;
            // cursor: default;
          }
        }
      }

      &.menu-item-has-children {
        position: relative;

        // & > a {
        //   &:hover {
        //     cursor: default;
        //   }
        // }

        &:hover > ul {
          @include media-breakpoint-up(sm) {
            z-index: 0;
            opacity: 1;
            display: block;
          }
        }
      }

      & > a {
        color: white;
        font-size: 2.2rem;
        line-height: 1.3em;
        padding: 20px;
        display: block;

        @include media-breakpoint-down(lg) {
          font-size: 2rem;
          padding: 17px;
        }

        @include media-breakpoint-down(md) {
         font-size: 1.9rem;
         padding: 14px;
        }

        @include media-breakpoint-down(sm) {
         font-size: 1.8rem;
         padding: 11px;
        }

        @include media-breakpoint-down(xs) {
         font-size: 1.7rem;
         padding: 8px;
        }

        &:hover {
          text-decoration: none;

          @include media-breakpoint-up(lg) {
            color: $color-sec;
          }
        }
      }

      /* second level */
      & > .sub-menu {
        position: relative;
        font-size: 0;

        @include media-breakpoint-up(md) {
          background: $grey-light;
          min-width: 300px;
        }

        @include media-breakpoint-down(sm) {
          border-top: 1px solid $color-pri;
        }

        // &:before {
        //   content: '';
        //   width: 0;
        //   height: 0;
        //   position: absolute;
        //   top: -10px;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   border-style: solid;
        //   border-width: 0 10px 10px 10px;
        //   border-color: transparent transparent $color-pri transparent;

        //   @include media-breakpoint-down(sm) {
        //     left: 40px;
        //     transform: translateX(0);
        //   }
        // }

        @include media-breakpoint-up(md) {
          z-index: -1;
          opacity: 0;
          display: none;
          position: absolute;
          top: 100%;
          left: 50%;
          transition: all 0.2s ease-in-out;
          transform: translateX(-50%);
        }

        li {
          width: 100%;

          &.active {
            & > a {
              text-decoration: none;
              color: $color-sec;

              &:hover {
                background: transparent;
                cursor: default;
              }
            }
          }

          // &:not(:first-child),
          // &:not(:last-child) {
          //  &:before {
          //    content: '';
          //    width: 1px;
          //    height: 50%;
          //    position: absolute;
          //    top: 50%;
          //    left: 0;
          //    transform: translateY(-50%);
          //    background: black;
          //  }
          // }
        }

        a {
          padding: 10px 15px;
          font-size: 1.8rem;
          font-weight: bold;
          color: $color-pri;
          display: block;

          @include media-breakpoint-down(lg) {
            font-size: 1.7rem;
          }

          @include media-breakpoint-down(md) {
            font-size: 1.6rem;
          }

          @include media-breakpoint-down(sm) {
           padding: 11px 11px 11px 20px;
           font-weight: 400;
           color: white;
          }

          @include media-breakpoint-down(xs) {
           padding: 8px 8px 8px 20px;
          }

          &:hover {
            text-decoration: none;

            @include media-breakpoint-up(md) {
              background: rgba($color-sec, .5);
            }
          }

          &:before {
            @include media-breakpoint-down(sm) {
              content: '- ';
              color: $grey;
            }
          }
        }

        /* third level */
        .sub-menu {
          a {
            font-weight: 400;
            font-size: 1.6rem;
            padding-left: 30px;

            @include media-breakpoint-down(md) {
              font-size: 1.5rem;
            }

            @include media-breakpoint-down(sm) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}

.nav-sec {
  .menu {
    & > li {
      & > a {
        // font-weight: bold;
      }
    }
  }
}

// Nav toggle
// https://codepen.io/designcouch/pen/Atyop
.nav-toggle {
  width: 23px;
  height: 18px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
    position: absolute;
    top: 33px;
    right: 15px;
  }

  &.open {

    span {
      &:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
    }
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    // border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
      height: 2px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }
}





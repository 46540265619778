// Search form
// .search-form {
//   @extend .form-inline;
// }
// .search-form label {
//   font-weight: normal;
//   @extend .form-group;
// }
// .search-form .search-field {
//   @extend .form-control;
// }
// .search-form .search-submit {
//   @extend .btn;
//   @extend .btn-secondary;
// }

form {
	text-align: left;

	textarea {
		resize: vertical;
	}

	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="date"],
	input[type="tel"],
	input[type="search"],
	input[type="password"],
	input[type="file"],
	select,
	textarea {
		font-family: $font-pri;
		font-size: 2rem;
		font-weight: bold;
		color: $color-pri;
		width: 100%;
		padding: 15px 20px;
		outline: none;
		transition: all 0.2s ease-in-out;
		border-radius: 0;
		background: white;
		border: 0;

		@include media-breakpoint-down(lg) {
			font-size: 1.5rem;
			padding: 13px 20px;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.4rem;
			padding: 11px 20px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.3rem;
			padding: 10px 20px;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.2rem;
			padding: 8px 20px;
		}

		&:focus {
			box-shadow: 0 0 1px $grey;
		}

		@include placeholder {
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 3.3rem;
		  color: $color-pri;
		  text-transform: uppercase;

		  @include media-breakpoint-down(lg) {
				font-size: 1.5rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 1.4rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 1.3rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 1.2rem;
			}
		}
	}

	textarea {
		width: 100%;
		height: 100px;
	}
}

.form {
	&.form-border {
		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="date"],
		input[type="tel"],
		input[type="search"],
		input[type="password"],
		input[type="file"],
		select,
		textarea {
			border: 1px solid $grey;
		}
	}
}

// /*---------------------------------------------
// ** GRAVITY FORMS
// ---------------------------------------------*/

.gform_body {
	.gform_fields {
		padding: 0;
	}

	.gfield {
		margin: 15px 0;
		list-style: none;
		vertical-align: top;
		padding: 0;

		&:first-child {
			margin-top: 0;
		}
	}

	.gfield_required {
		color: $red;
		font-size: 1.3rem;
		vertical-align: text-top;
	}

	.gfield_label,
	.gfield_visibility_ {
		display: none;
	}
}

.gform_footer {
	text-align: center;

	.gform_button {
		background: $color-sec;
		border: 0;
		color: white;
		font-size: 2rem;
		padding: 15px 30px;
		margin: 0;
	}
}

.validation_message {
	color: $red;
	margin-top: 5px;
	font-size: 1.3rem;
	font-weight: bold;
}

.gfield_error {
	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="date"],
	input[type="search"],
	input[type="password"],
	input[type="file"],
	input[type="tel"],
	select,
	textarea {
		border-color: $red !important;
		color: $red !important;
		
		@include placeholder {
		  color: $red !important;
		}
	}
}
.validation_error {
	color: $red;
	font-size: 1.5rem;
	font-weight: bold;
	margin: 10px 0;
}

.gform_confirmation_message {
	color: white;
  background: $green;
  padding: 20px;
  font-size: 1.5rem;
}







footer {
	background: $color-pri;
	text-align: center;
	padding: 20px;

	.copy,
	.link {
		color: $grey;
		font-size: 1.25rem;
	}
}
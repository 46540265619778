.content {
	.text {
		a:not(.button) {
			text-decoration: underline;

			&:hover {
				color: $color-sec;
			}
		}
	}
}



section.portfolio {
	text-align: center;
	padding-top: 60px;

	@include media-breakpoint-down(lg) {
		padding-top: 60px;
	}

	@include media-breakpoint-down(md) {
		padding-top: 40px;
	}

	@include media-breakpoint-down(sm) {
		padding-top: 30px;
	}

	#portfolio {
		&.loading {
			opacity: 0.5;
		}
	}

	.portfolio-wrapper {
		background: $grey-dark;
		padding: 40px 0;
	}

	.nav-filters {
		margin: 50px 0 70px;
		display: inline-block;

		@include media-breakpoint-down(sm) {
			margin: 0 0 20px;
			background: transparent;
			padding: 0;	
			width: 100%;
		}

		.filters {
			@include media-breakpoint-down(sm) {
				display: none;
				text-align: left;
				background: lighten($grey-light, 10%);
				padding: 5px 10px;
			}

			@include media-breakpoint-up(md) {
				display: block !important;
			}
		}

		.nav-filters-wrapper {
			@include media-breakpoint-up(md) {
				display: none;
			}
		}

		li {
			display: inline-block;
			margin: 0 15px;

			@include media-breakpoint-down(lg) {
				margin: 0 10px;
			}

			@include media-breakpoint-down(md) {
				margin: 0 5px;
			}

			@include media-breakpoint-down(sm) {
				margin: 15px;
				display: list-item;
			}

			// @include media-breakpoint-down(xs) {
			// 	margin: 0 15px;
			// }

			// &.parent {
			// 	position: relative;
			// }

			&.active {
				& > a {
					color: $color-sec;
					font-weight: bold;
				}

				.sub-filters {
					display: block!important;
				}
			}

			&.parent {
				&:hover {
					.sub-filters {
						@include media-breakpoint-up(md) {
							display: block!important;
						}
					}
				}
			}
		}

		a {
			font-size: 1.8rem;
			font-weight: 600;
			padding-bottom: 20px;

			@include media-breakpoint-down(lg) {
				font-size: 1.7rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 1.6rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 1.5rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 1.4rem;
			}

			&:hover,
			&:focus {
				color: $color-sec;
				text-decoration: none;
			}
		}

		.sub-filters {
			font-size: 0;

			@include media-breakpoint-up(md) {
				display: none;
				background: lighten($grey-light, 10%);
				position: absolute;
				top: inherit;
				left: inherit;
				margin-top: 10px;
			}

			&:after {
				@include media-breakpoint-up(md) {
					bottom: 100%;
					left: 10%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(136, 183, 213, 0);
					border-bottom-color: lighten($grey-light, 10%);
					border-width: 10px;
					margin-left: -10px;
				}
			}

			li {
				list-style: none;
				margin: 0;

				@include media-breakpoint-up(md) {
					float: left;
				}

				&.active {
					a {
						background: $color-sec;
						color: white;
					}
				}
			}

			a {
				font-size: 1.4rem;
				display: block;
				padding: 5px 15px;
			}
		}
	}

	.nav-filters-toggle {
		.fa {
			margin-left: 5px;
		}
	}

	.portfolio-content {
		position: relative;

		.fa {
			display: none;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 4;
			font-size: 4rem;
			color: black;
		}
	}

	div[class^="col-"] {
		padding-left: 7.5px;
		padding-right: 7.5px;
	}

	.button {
		margin: 30px 15px 0 15px;
	}
}

.portfolio,
.other-projects {

	.grid-sizer,
	.grid-item {
		width: calc(100% / 3 - 10px);
		height: 350px;
		margin-bottom: 15px;

		@include media-breakpoint-down(lg) {
			height: 300px;
		}

		@include media-breakpoint-down(md) {
			height: 250px;
		}

		@include media-breakpoint-down(sm) {
			height: 200px;
		}

		@include media-breakpoint-down(xs) {
			height: 150px;
			width: calc(100% / 2 - 10px);
		}

		&.grid-item-big {
			height: 450px;

			@include media-breakpoint-down(lg) {
				height: 400px;
			}

			@include media-breakpoint-down(md) {
				height: 350px;
			}

			@include media-breakpoint-down(sm) {
				height: 100px;
			}

			@include media-breakpoint-down(xs) {
				height: 150px;
			}
		}
	}

	.portfolio-item {
		display: block;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		line-height: 2rem;


		&:hover {
			.portfolio-details {
				@include media-breakpoint-up(sm) {
					opacity: .8;
					z-index: 2;
				}
			}
		}

		img {
			height: 100%;
			max-width: initial;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			transition: all 0.2s ease-in-out;
		}

		.portfolio-details {
			width: 100%;
			height: 100%;
			transition: all 0.2s ease-in-out;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			opacity: 0;
			background: $color-sec;
		}

		.portfolio-details-text {
			color: white;
			font-size: 2rem;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 1em;
			border: 2px solid white;
			padding: 10px 15px;
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 2;

			@include media-breakpoint-down(lg) {
				font-size: 1.6rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 1.4rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 1.2rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 1rem;
			}
		}

		.portfolio-details-plus {
			display: inline-block;
			font-size: 10rem;
			line-height: 0.5em;
			font-weight: 400;
			color: white;
			position: absolute;
			bottom: 15px;
			right: 13px;
			z-index: 2;

			@include media-breakpoint-down(lg) {
				font-size: 8rem;
				bottom: 5px;
				right: 5px;
			}

			@include media-breakpoint-down(md) {
				font-size: 6rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 4rem;
				bottom: 5px;
				right: 5px;
			}

			@include media-breakpoint-down(xs) {
				font-size: 4rem;
			}
		}

		.portfolio-title {
			color: $grey-dark;
			font-family: $font-pri;
			font-size: 1.6rem;
			font-weight: bold;
			text-transform: uppercase;
			margin-top: 30px;
		}

		.portfolio-city {
			color: $color-pri;
			font-size: 1.1rem;
			text-transform: uppercase;
			margin-top: 15px;
		}
	}
}

.other-projects {
	.title {
		margin-top: 50px;
		font-size: 2rem;
	}
}

section.contact {
	@include section-padding;
	text-align: center;
	position: relative;

	.form {
		margin-top: 50px;
	}

	.col-33 {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;

		@include media-breakpoint-up(md) {
			width: 32%;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		&:nth-child(even) {
			@include media-breakpoint-up(md) {
				margin: 0 2%;
			}

			@include media-breakpoint-down(sm) {
				margin: 15px 0;
			}
		}
	}
}

.gmap {
	@include media-breakpoint-up(lg) {
		background-image: url('../images/map.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		text-align: right;
	}

	.content {
		background: #5e5f5f;
		padding: 100px 50px;
		display: inline-block;
		text-align: center;

		@include media-breakpoint-down(md) {
			width: 100%;
			padding: 50px;
		}

		@include media-breakpoint-down(md) {
			padding: 40px;
		}

		@include media-breakpoint-down(sm) {
			padding: 30px;
		}
	}

	.logo {
		// background: $color-pri;
		padding: 20px 40px;
		display: block;
		margin: 0 auto 40px;

		@include media-breakpoint-down(md) {
			display: none;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}

		@include media-breakpoint-down(xs) {
			display: none;
		}

	}

	.links {
		text-align: left;
		margin-bottom: 40px;
		display: inline-block;
		@include media-breakpoint-down(lg) {
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 20px;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 10px;
		}

		@include media-breakpoint-down(xs) {
			margin-bottom: 0px;
		}
	}

	.link,
	.text {
		color: white;
		font-size: 2rem;
		// font-weight: bold;
		line-height: 1.2em;
		display: block;
		margin-bottom: 15px;

		@include media-breakpoint-down(lg) {
			font-size: 1.9rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.8rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.7rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 1.6rem;
		}

		&:hover {
			text-decoration: none;
			color: $grey;
		}

		&:before {
			font-family: 'FontAwesome';
			color: lighten(#5e5f5f, 15%);
			font-size: 4rem;
			float: left;
			margin-right: 15px;
			vertical-align: middle;
		}

		&.link-map {
			&:before {
				content: '\f041';
				padding: 10px 0;
			}
		}

		&.link-phone {
			font-size: 2.3rem;

			@include media-breakpoint-down(lg) {
				font-size: 2.2rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 2.1rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 2rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 1.9rem;
			}
			
			&:before {
				content: '\f10b';
				margin-right: 20px;
			}
		}

		&.text-fax {
			font-size: 2.3rem;

			@include media-breakpoint-down(lg) {
				font-size: 2.2rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 2.1rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 2rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 1.9rem;
			}

			&:hover {
				color: white;
			}

			&:before {
				content: '\f1ac';
				padding: 10px 0;
				font-size: 2.5rem;
			}
		}

	}

	.socials {
		margin-bottom: 0;
	}
}


.socials {
	margin: 15px 0;
}

.social {
	width: 35px;
	height: 35px;
	background: $grey;
	border-radius: 50%;
	color: white;
	font-size: 2.5rem;
	position: relative;
	display: inline-block;
	margin: 0 5px;

	&:hover {
		color: $color-pri;
	}

	.fa {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}


.page-about {
	position: relative; 

	.about-image {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		// overflow: hidden;

		@include media-breakpoint-up(lg) {
			height: 100%;
			background-attachment: fixed;
			background-size: contain;
			background-position: left center;
		}

		@include media-breakpoint-down(md) {
			height: 450px;
			padding: 60px 60px 0;
		}

		@include media-breakpoint-down(sm) {
			height: 350px;
			padding: 40px 40px 0;
		}

		@include media-breakpoint-down(xs) {
			height: 250px;
			padding: 20px 20px 0;
		}

		// img {
		// 	@include media-breakpoint-up(lg) {
		// 		height: 100%;
		// 		max-width: initial;
		// 		margin-left: -50%;
		// 	}

		// 	@include media-breakpoint-down(md) {
		// 		max-height: 50vh;
		// 	}
		// }
	}

	.content {
		@include section-padding;
		
		@include media-breakpoint-down(lg) {
			padding: 60px;
		}

		@include media-breakpoint-down(sm) {
			padding: 40px 40px;
		}

		@include media-breakpoint-down(xs) {
			padding: 20px 20px;
		}

		h2 {
			position: relative;
			margin-bottom: 30px;

			&:after {
				content: '';
				width: 60px;
				height: 2px;
				background: $color-sec;
				position: absolute;
				bottom: -15px;
				left: 0;
			}
		}
	}

	.follow-us {
		margin-top: 30px;
		text-align: right;

		@include media-breakpoint-down(xs) {
			text-align: center;
		}
	}

	.follow-text {
		font-family: $font-pri;
		font-size: 1.2rem;
		text-transform: uppercase;
		display: inline-block;
		padding: 10px 15px 10px 0;
		vertical-align: top;
	}
}

.gmap-responsive {
	width: 100%;
	height: 400px;
	background-image: url('../images/map.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;

	.button-directions {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
}


.not-found {
	@include section-padding;
	text-align: center;
}









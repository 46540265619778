button,
a.button,
.button,
.btn,
input[type="submit"],
.post-button a,
.gform_button {
	color: $color-pri;
	background: transparent;
	font-family: $font-pri;
	font-size: 1.3rem;
	font-weight: bold;
	line-height: 1.2em;
	text-transform: uppercase;
	display: inline-block;
	text-align: center;
	margin: 30px auto 20px;
	padding: 10px 25px;
	transition: all 0.2s ease-in-out;
	border: 2px solid $color-pri;
	border-radius: 3px;

	&:hover,
	&:focus {
		background: $color-pri;
		color: white;
		text-decoration: none;
		cursor: pointer; // For gravity form pointer
	}

	&.button-inverted {
		background: $color-pri;
		color: white;

		&:hover,
		&:focus {
			color: $color-pri;
			background: transparent;
			border: 2px solid $color-pri;
		}
	}

	&.button-white {
		border-color: white;
		color: white;

		&:hover,
		&:focus {
			color: $color-pri;
			background: white;
			border-color: white;
		}
	}

	&.load-more-posts-link {
		font-size: 8rem;
		font-weight: 400;
		line-height: 4rem;
		width: 200px;
		max-width: 100%;
		color: $grey;
		border-color: $grey;
	}
}









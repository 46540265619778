header {
	position: relative;
	background: $color-pri;
	width: 100%;
	padding: 10px;

	@include media-breakpoint-down(lg) {
		padding: 9px;
	}

	@include media-breakpoint-down(md) {
		padding: 8px;
	}

	@include media-breakpoint-down(sm) {
		padding: 7px;
	}

	@include media-breakpoint-down(xs) {
		padding: 6px;
	}

	// @include media-breakpoint-up(lg) {
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// 	z-index: 8;
	// }

	.brand {
		display: inline-block;

		img {
			max-width: 100px;
		}
	}

	.button-phone {
	  @include media-breakpoint-down(xs) {
	    display: none;
	  }
	}

	// .nav-lang {
	// 	visibility: hidden;
	// }
}













